/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.carousel-cell img{object-fit: contain !important;}
.imgc{
  overflow: hidden;
  position: relative;
}
.txt-titulos{z-index: 9;}
.tituloOverlay{line-height: 0.9;font-size: 54px !important;}
.subtituloOverlay{margin: 25px 0 !important;
}
.container-banner{display: flex;align-items: center;}
.toolbar-ghost{
  background: linear-gradient(to bottom, rgba(0,0,0,0.5)0, rgba(0,0,0,0.1) , rgba(0,0,0,0) 100%);
  //background: #787777;
  position: fixed;
  width: 100%;
  z-index: 9;
  color: #fff;
  height: 60px;
  padding: 0 25px;
  display: flex;
}
@media (prefers-color-scheme: dark) {
  .md body{--ion-background-color: #303030;}
  .titulo-accordion{color: rgba(255,255,255,0.8) !important;}
  .txt-content .txt{color: rgba(255,255,255,0.6) !important;}
  //.toolbar-ghost{background: #303030;}
  .toolbar-ghost{background: linear-gradient(to bottom, rgba(0,0,0,0.5)0, rgba(0,0,0,0.1) , rgba(0,0,0,0) 100%);}
  .btn-paquete,.card-item{background: rgba(255,255,255,0.2) !important;}
  .grayD{color: rgba(255,255,255,0.8) !important;}
  .btn-paquete .grayW{color: rgba(255,255,255,0.5) !important;}
  .card-item .grayW{color: rgba(255,255,255,0.7) !important;text-transform: uppercase;}
  .cont-paquetes{text-transform: uppercase;}
}
